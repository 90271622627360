@import '../../../../scss/theme-bootstrap';

.footer-social-media {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__menu {
    display: flex;
  }
  &-item {
    &-link {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      width: 30px;
      height: 35px;
      display: block;
      #{$ldirection}: -5px
    }
    svg {
      width: 20px;
      height: 20px;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      &.icon--youtube--play {
        width: 40px;
        height: 40px;
      }
      &.icon--twitter {
        width: 28px;
        height: 25px;
      }
      &.icon--tiktok {
        width: 40px;
        height: 25px;
        margin-#{$ldirection}: 7px;
      }
    }
  }
  &__logo {
    display: none;
    padding-#{$ldirection}: 5px;
    padding-bottom: 15px;
    @include breakpoint($landscape-up) {
      display: block;
    }
    .icon--logo {
      width: 130px;
    }
  }
}
